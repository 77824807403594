import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { toSvg } from "jdenticon";
// var duration = require("dayjs/plugin/duration");
// dayjs.extend(duration);
dayjs.extend(relativeTime);

export default {
  formatTime(dateStr, format) {
    if (!dateStr) return null;
    if (format) return dayjs(dateStr).format(format);
    return dayjs(dateStr).format("YYYY/MM/DD HH:mm:ss");
  },

  // 相对时间
  DateFormNowNt(dateStr, locale) {
    let d = dayjs(dateStr);
    if (locale) {
      d = dayjs.locale(locale);
    }
    return dayjs(dateStr).fromNow(true);
  },
  DateToNowNt(dateStr) {
    return dayjs(dateStr).toNow(true);
  },
  TimeFromNow(dateStr) {
    const targetTime = dayjs(dateStr); // 设定目标时间
    const currentTime = dayjs(); // 获取当前时间

    if (targetTime.isBefore(currentTime)) {
      return "00:00:00"; //不是未来时间直接返回
    }

    const diffDuration = dayjs.duration(targetTime.diff(currentTime)); // 计算时间差
    const hoursDiff = diffDuration.hours();
    const minutesDiff = diffDuration.minutes();
    const secondsDiff = diffDuration.seconds();

    const formattedTime = `${hoursDiff
      .toString()
      .padStart(2, "0")}:${minutesDiff
      .toString()
      .padStart(2, "0")}:${secondsDiff.toString().padStart(2, "0")}`;
    return formattedTime;
  },
  isBefore(dateStr) {
    return dayjs().isBefore(dayjs(dateStr));
  },
  // 获取操作系统
  getSystem() {
    const str = navigator.userAgent.toLowerCase();

    let start = str.indexOf("; ");
    let end = str.indexOf(")");
    let version = str.substring(start + 2, end);
    let system;
    if (version.includes("win")) system = "win";
    if (version.includes("linux")) system = "linux";
    //  let v = "";
    if (version.includes("mac")) {
      system = "darwin";
    } else {
      //v = ""; version.includes("64") ? (v = "-amd64") : (v = "-386");
    }

    return system; //+ v;
  },
  // 乘法
  accMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  },
  highlightSearchKeywords(value, keywords) {
    if (!keywords) {
      return value;
    }
    return value.replace(
      keywords,
      `<font style='color:orange'>${keywords}</font>`
    );
  },
  clearQueryParams() {
    if (window.history.pushState) {
      var baseUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      var urlWithTitle = baseUrl; // 如果你想同时修改浏览器标题，可以在这里指定
      window.history.pushState({ path: baseUrl }, "", baseUrl);
    }
  },
  longName(value, length = 20) {
    if (!value || value.length < length) {
      return value;
    }
    return value.substring(0, length) + "...";
  },
  identicon(str, width = 32) {
    return toSvg(str, width);
  },
  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
};
