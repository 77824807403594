export default [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "Home",
    },
    children: [
      {
        path: "/devices",
        name: "devices",
        component: () => import("@/views/Devices.vue"),
        meta: {
          title: "Devices",
          logged: true,
        },
      },
      {
        path: "/rentDevices/:ownerId",
        name: "rent-devices",
        component: () => import("@/views/RentDevices.vue"),
        meta: {
          title: "Rent Devices",
          logged: true,
        },
      },
      {
        path: "/user/rent",
        name: "user-rent",
        component: () => import("@/views/RentUser.vue"),
        meta: {
          title: "User-Rent",
          logged: true,
        },
      },
      {
        path: "/tokens",
        name: "tokens",
        component: () => import("@/views/Token.vue"),
        meta: {
          title: "Tokens",
          logged: true,
        },
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/Orders.vue"),
        meta: {
          title: "Orders",
          logged: true,
        },
      },
      {
        path: "/userSettings",
        name: "user-settings",
        component: () => import("@/views/user/Settings.vue"),
        meta: {
          title: "User-Settings",
          logged: true,
        },
      },
      {
        path: "/userSubscription",
        name: "user-Subscription",
        component: () => import("@/views/user/Subscription.vue"),
        meta: {
          title: "User-Subscription",
          logged: true,
        },
      },
      {
        path: "/userPoints",
        name: "points",
        component: () => import("@/views/user/Points.vue"),
        meta: {
          title: "User-Points",
          logged: true,
        },
      },
      // {
      //   path: "/user/profile/:userId",
      //   name: "user-profile",
      //   component: () => import("@/views/user/Profile.vue"),
      //   meta: {
      //     title: "User-Profile",
      //   },
      // },
    ],
  },

  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/Pricing.vue"),
    meta: {
      title: "Pricing",
      logged: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
    meta: {
      title: "Register",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/findPassword",
    component: () => import("@/views/FindPassword.vue"),
    meta: {
      title: "Forgot your password",
    },
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { title: "NotFound" },
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/views/home/Privacy.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "/terms",
    component: () => import("@/views/home/Terms.vue"),
    meta: {
      title: "Terms of Service",
    },
  },
];
