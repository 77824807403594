import { createRouter, createWebHistory } from "vue-router";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import routes from "./routers.js";
import { useStore } from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  const store = useStore();

  if (to.meta.title) {
    if (store.systemData.brand_name) {
      document.title = `${store.systemData.brand_name} - ${to.meta.title}`;
    } else {
      document.title = `${to.meta.title}`;
    }
  }

  if (to.meta.logged && !store.logged) {
    return "/login";
  }

  if (to.path == "/") {
    if (store.logged) {
      document.title = `${store.systemData.brand_name} - Devices`;
      return "/devices";
    }
  }

  if (to.path !== from.path) {
    NProgress.configure({ showSpinner: false });
    NProgress.start();
  }
});

router.afterEach(() => {
  // 在路由完成后结束 NProgress
  NProgress.done();
});

export default router;
