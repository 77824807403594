<template>
  <nav aria-label="Page navigation example">
    <ul class="flex items-center -space-x-px h-8 text-sm">
      <!-- 第一页 -->
      <li
        class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        :class="currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700'"
        @click="firstPage" :disabled="currentPage == 1">

        <span aria-hidden="true">&laquo;</span>

      </li>

      <li @click="prevPage" :disabled="currentPage === 1">
        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border  border-gray-300   dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          :class="currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700'">
          <span class="sr-only">Previous</span>
          <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 1 1 5l4 4" />
          </svg>
        </a>
      </li>
      <li v-for="item in pageTotal" :key="item" @click="getCurPage(item)"
        v-show="item <= maxPage + deviation && item > deviation">
        <a v-if="item != currentPage"
          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
          {{ item }}</a>
        <a v-else x
          class="flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer">
          {{ item }}</a>
      </li>

      <li @click="nextPage">
        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300   dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          :class="currentPage === pageTotal ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700'">
          <span class="sr-only">Next</span>
          <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 9 4-4-4-4" />
          </svg>
        </a>
      </li>

      <li
        class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white "
        :class="currentPage === pageTotal ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700'"
        @click="lastPage" :disabled="currentPage === pageTotal">

        <span aria-hidden="true">&raquo;</span>

      </li>
    </ul>
  </nav>
  <Menu as="div" class="relative inline-block text-left" v-if="showSizeChanger">
    <div>
      <MenuButton
        class="flex items-center justify-center text-sm px-3 h-8 leading-tight text-gray-500 bg-white border rounded-lg ms-2 border-gray-300   dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-100 hover:text-gray-700 text-nowrap">
        {{ limit }} items / page
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="absolute right-0 bottom-full z-10 mb-2  origin-top-right rounded-md bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }" v-for="size in pageSizeOptions">
          <a class=" cursor-pointer hover:bg-gray-100 hover:text-gray-700 text-gray-700 block px-4 py-2 text-sm"
            @click="$emit('pageSizeChange', size)">{{ size
            }} items / page</a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { computed, watch, reactive, toRefs } from "vue";
export default {
  props: {
    limit: {
      type: Number,
      default: 10,
    },
    pageSizeOptions: {
      type: Array,
      default: [5, 10, 15, 20, 25, 30]
    },
    showSizeChanger: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0,
    },
    maxPage: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: ["change", "pageSizeChange"],
  setup(props, { emit }) {
    const state = reactive({
      deviation: 0,
    });
    const pageTotal = computed(() => Math.ceil(props.total / props.limit));

    watch(
      () => props.currentPage,
      (val) => {
        pageAdd(val);
        pageCut(val);
      }
    );

    function getCurPage(i) {
      if (props.currentPage === i) return;
      emit("change", i);
    }

    function firstPage() {
      if (props.currentPage === 1) return;
      emit("change", 1);
      state.deviation = 0;
    }

    function prevPage() {
      if (props.currentPage === 1) return;
      emit("change", props.currentPage - 1);
    }

    function nextPage() {
      if (props.currentPage === pageTotal.value) return;
      emit("change", props.currentPage + 1);
    }

    function lastPage() {
      if (props.currentPage === pageTotal.value) return;
      emit("change", pageTotal.value);

      if (pageTotal.value > props.maxPage) {
        state.deviation = pageTotal.value - props.maxPage;
      }
    }

    function pageAdd(i) {
      if (
        props.total > props.maxPage &&
        i >= props.maxPage &&
        i !== pageTotal.value
      ) {
        if (i === state.deviation + props.maxPage) {
          state.deviation += 1;
        }
      }
    }

    function pageCut(i) {
      if (i == 1) state.deviation = 0;
      if (pageTotal.value > props.maxPage && i != 1) {
        if (i === state.deviation + 1) {
          state.deviation -= 1;
        }
      }
    }

    return {
      ...toRefs(state),
      getCurPage,
      pageTotal,
      firstPage,
      lastPage,
      prevPage,
      nextPage,
    };
  },
};
</script>
  
<style scoped></style>