<template>
    <div class="row mt-3">
        <div class="col-md-12 box text-center" :style="{ padding: `${pt}px 0 ${pb}px 0` }">
            <img src="/img/empty.svg" alt />
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        pt: {
            type: String,
            default: '150',
        },
        pb: {
            type: String,
            default: '150',
        },
    },
    data() {
        return {};
    },
};
</script>
<style  scoped>

img {
    width: 60px;
}
</style>
