import { createApp } from "vue";
import "./style.css";
import "./index.css";
import "./version";

import Clipboard from "v-clipboard";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import $axios from "@/utils/axios";
import $msg from "@/components/layer/msg";
import $filters from "@/utils/filters";

import Pagination from "@/components/layer/Pagination.vue";
import Loading from "@/components/layer/Loading.vue";
import Empty from "@/components/layer/Empty.vue";
import Footer from "@/components/Footer.vue";

const app = createApp(App);

app.config.globalProperties.$filters = $filters;

app.provide("$", { $axios, $msg, $filters });

app.component("Pagination", Pagination);
app.component("Loading", Loading);
app.component("Empty", Empty);
app.component("Footer", Footer);

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import { ChevronDownIcon, HomeIcon } from "@heroicons/vue/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

app.component("Menu", Menu);
app.component("MenuButton", MenuButton);
app.component("MenuItem", MenuItem);
app.component("MenuItems", MenuItems);
app.component("ChevronDownIcon", ChevronDownIcon);
app.component("TransitionRoot", TransitionRoot);
app.component("TransitionChild", TransitionChild);
app.component("Dialog", Dialog);
app.component("DialogPanel", DialogPanel);
app.component("DialogTitle", DialogTitle);
app.component("ExclamationTriangleIcon", ExclamationTriangleIcon);
app.component("HomeIcon", HomeIcon);

app.use(router).use(store).use(Clipboard).mount("#app");

import VCalendar from "v-calendar";
import "v-calendar/style.css";

// Use plugin with optional defaults
app.use(VCalendar, {});

window.addEventListener("popstate", function (e) {
  // 如果点击了前进或后退按钮，执行相应的操作，比如弹出提示框

  document.querySelector("[modal-backdrop]")?.remove();
});
