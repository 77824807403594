<script setup>
import { useStore } from './store';
const store = useStore()
try {
  store.getSettings()
  if (store.getUserId) {
    store.getAttrs()
  }
} catch (error) {
  console.error(error);
}
</script>

<template>
  <RouterView />
  <div id="Notify"></div>
</template>

<style scoped></style>
