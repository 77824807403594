<template>
  <div class="w-full  text-center text-sm text-gray-500 p-4 sm:p-6 dark:text-gray-300">©{{ year }} {{ systemData.brand_name }}
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
export default {
  name: '',
  props: [],
  setup() {
    const { $axios, $msg } = inject('$')
    const v = reactive({
      year: new Date().getFullYear()
    })
    const store = useStore()

    const { systemData, } = storeToRefs(store)

    return {
      ...toRefs(v),
      systemData
    }
  }
}
</script>
<style scoped></style>