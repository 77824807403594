import { defineStore } from "pinia";
import axios from "../../utils/axios";

const config = getConfig();

export const useStore = defineStore("main", {
  state: () => ({
    userInfo: localStorage.getItem("_tokenInfo")
      ? JSON.parse(localStorage.getItem("_tokenInfo")).user
      : {},
    systemData: localStorage.getItem("_systemData")
      ? JSON.parse(localStorage.getItem("_systemData"))
      : {},
    attr: null,
    labels: null,
    striptAccount: null, //默认null 就去获取 true是有 false是没有 不必再获取
    paypalEmail: null,
    prompt: false,
    useStripe: !!config.stripe.publicKey,
    usePayPal: !!config.paypal.clientId,
  }),
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },
    getUserId(state) {
      return state.userInfo?.id;
    },
    logged(state) {
      return !!state.userInfo?.id;
    },
    invitationCode(state) {
      return state.userInfo.invitation_code;
    },
  },
  actions: {
    setUserInfo(val) {
      this.userInfo = val;
      let tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));
      tokenInfo.user = val;
      localStorage.setItem("_tokenInfo", JSON.stringify(tokenInfo));
    },
    async getSettings() {
      try {
        const res = await axios.openget("/api/v1/system_settings");
        this.systemData = res.data;
        localStorage.setItem("_systemData", JSON.stringify(res.data));
      } catch (error) {
        console.error(error);
      }
    },
    async getLables(searchText) {
      try {
        const res = await axios.get("/api/v1/labels", {
          search_text: searchText,
        });
        this.labels = res.data;
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAttrs(searchText) {
      try {
        const res = await axios.get("/api/v1/attributes", {
          search_text: searchText || null,
        });
        this.attr = res.data;
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getStripeConnectAccount() {
      try {
        const res = await axios.get(
          "/api/v1/finance/settings/stripeConnectedAccount"
        );
        if (res) {
          this.striptAccount = true;
        }
      } catch (error) {
        this.striptAccount = false;
        console.error(error);
      }
    },
    async getPayPalEmail() {
      try {
        const res = await axios.get("/api/v1/paypal/email");
        this.paypalEmail = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSubData() {
      const res = await axios.get("/api/v1/subscription");
      if (!res.data) return;
      if (res.data.cancel_at_period_end) return;
      this.prompt = res.data?.update_url ? true : false;
    },
  },
});
